export const deviceSize = {
  phone: 576,
  tablet: 768,
  desktop: 992,
  desktopLarge: 1200
}

export default {
  phone: `(max-width: ${deviceSize.phone}px)`,
  tablet: `(max-width: ${deviceSize.tablet}px)`,
  desktop: `(max-width: ${deviceSize.desktop}px)`,
  desktopLarge: `(max-width: ${deviceSize.desktopLarge}px)`,
}