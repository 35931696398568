import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import device from '../../styles/device'


const SideNavPrimaryPureS = styled.ul`
  margin: 0 190px 0 0;
  padding: 0;

  @media ${device.tablet}{
    margin-right: 0;
  }
`

const ListItem = styled.li`
  font-family: ${fonts.poppins};
  font-size: 33px;
  font-weight: bold;
  list-style: none;
  margin-bottom: 25px;
  padding: 0;

  @media ${device.phone}{
    font-size: 23px;
    line-height: 26px;
  }

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    letter-spacing: -0.71px;
    position: relative;
    opacity: ${ ({active}) => active ? 1 : 0.3};
    pointer-events: ${({active}) => !active ? "none" : null};


    @media ${device.phone}{
      letter-spacing: -0.43px;
    }
  }

  a:after{
    position: absolute;
    left: 0;
    top: 25px;
    content: '';
    width: 100%;
    height: 4px;
    background-image: ${ ({active}) => active && `linear-gradient(135deg, #2214FE 0%, ${colors.secondary} 100%) !important` };
    transition: .4s;
    transform: scaleX(0);

    @media ${device.phone}{
      top: 17px;
    }
  }

  a:hover::after{
    transform: ${ ({active}) => active && "scaleX(1)"};

    @media ${device.tablet}{
      transform: scaleX(0);
    }
  }
`

const SideNavPrimary = ({ handleStatus, primary }) => {
  
  const currentUrl = typeof window !== "undefined" && window.location.pathname

  return (
    <SideNavPrimaryPureS>

      { primary.map((section, i) => {

        let active = true
        currentUrl === section.slug ? (active = false) : (active = true)

        return (
          <ListItem key={i} active={active}><Link to={ section.slug } onClick={ () => handleStatus() }>{ section.name }</Link></ListItem>
        )
      })}

    </SideNavPrimaryPureS>
  )
};

export default SideNavPrimary;