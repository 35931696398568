import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

import device, {deviceSize}  from '../../styles/device'
import colors from "../../styles/colors"

import SideNavPrimary from './sideNavPrimary'
import SideNavSocial from './sideNavSocial'
import SideNavLang from './sideNavLang'

const SideNavWrap = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`
// el width del container del sidenav se establece según el estado de la transición
const SideNavContainer = styled.div`
  background-color: #FFFFFF;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  transition: 0.4s;
  width: ${({ state }) => (state === "entering" || state === "entered" ? "0" : "100%")};

  @media ${device.tablet}{
    padding-top: 100px;
    align-items: flex-start;
  }
`
const SideNavContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ${device.tablet}{
    width: 100%;
    margin: 150px 150px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.phone}{
    width: 100%;
    margin: 60px 20px;
    flex-direction: column;
    align-items: space-between;
  }
`

const UpperPart = styled.div`
  display: flex;

  @media ${device.phone}{
    justify-content: space-between;
  }
`

const SideNavBtn = styled.span`
  position: relative;
  background-color: ${({ negative }) => negative === "light" ? "#fff" : colors.secondary  };
  border-radius: 100%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  z-index: 10;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  transition: .2s;

  &::before {
    position: absolute;
    content: "";
    top: ${({status}) => ( status ? 18 : 24 )}px;
    left: 14px;
    width: 20px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: ${({ negative }) => negative === "light" ? "#2214fe" : "#fff" };
    transition: .2s;
    transform: rotate( ${({status}) => (status ? "0deg" : "45deg")} );
  }

  &::after {
    position: absolute;
    content: "";
    top: ${({status}) => ( status ? 28 : 24 )}px;
    left: 14px;
    width: 20px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: ${({ negative }) => negative === "light" ? colors.secondary : "#fff"};
    transition: .2s;
    transform: rotate( ${({status}) => (status ? "0deg" : "-45deg")} );
  }

  &:hover {
    background-color: ${({ negative }) => negative === "light" ? "#EFEFEF" : colors.secondaryDark  };
    transform: scale(1.1)
  }
`

const SideNavRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.phone}{
    justify-content: space-between;
    flex-direction: row;
  }
`

export const SideNav = ({ data, sideMenu: {primary, languages}, negative, switchColors, handleStatus, status }) => {


  const [windowWidth, setWindowWidth] = useState(0)

  let isPhone = windowWidth <= deviceSize.phone;

  useEffect( () => {
    const setWidth = () => setWindowWidth(window.innerWidth);

    if(typeof window !== 'undefined') {
      setWidth();
      window.addEventListener("resize", () => setWidth)
    }

    return () => window.removeEventListener("resize", () => setWidth)

  }, []);

  const onClick = () => {
    handleStatus()
    switchColors()
  }


  return(
    <SideNavWrap>

      <Transition in={status} timeout={200}>

        {(state) => (
          <SideNavContainer state={state}>
            <SideNavContent>

              <UpperPart>
                <SideNavPrimary handleStatus={handleStatus} primary={primary}/>
                {isPhone && <SideNavLang handleStatus={handleStatus} languages={languages}/>}
              </UpperPart>

              <SideNavRight>
                {!isPhone && <SideNavLang handleStatus={handleStatus} languages={languages}/>}
                <SideNavSocial/>
              </SideNavRight>
              
            </SideNavContent>
          </SideNavContainer>
        )}
        
      </Transition>

      <SideNavBtn negative={negative} status={status} onClick={ () => onClick() }/>

    </SideNavWrap>
  )
}

export default SideNav