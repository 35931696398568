import React from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import FooterSocial from "./footerSocial"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import device from "../styles/device"

import logoImg from '../images/logo.svg'


const FooterSection = styled.footer`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.poppins};

  margin: auto 0 0;

  &::before {
    content: "";
    width: 200vw;
    margin-left: -50vw;
    height: 4px;
    background: linear-gradient(270deg, ${colors.primary}, ${colors.secondary});
    background-size: 200% 100%;
    animation: AnimationName 10s ease infinite;
    z-index: 1;
  }

  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const FooterContainer = styled.div`
    max-width: 1440px;
    
    margin: auto;
    padding: 40px 8.3333% 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;

    @media ${device.tablet}{
        padding: 40px 30px 20px;
    }

    @media ${device.phone}{
        padding: 40px 20px 20px;
    }
`

const FooterRow = styled.div`
    display: flex;
    flex-direction: column;
    
    margin: 0 -10px;

    @media ${device.tablet}{
        flex-direction: column;
    }
`

const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-items: center;
  
  position: relative;

  padding: 0 10px 60px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media(max-width: 1160px) {
    flex-wrap: wrap;
    padding: 0 10px 20px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    background: ${colors.lightGrey};
    height: 1px;
    width: 200vw;
    left: -50vw;
  }
  
  .logoInitial {
    height: 21px;
  }
`
const Logo = styled(Link)`
  display:flex;

  img {
    max-width: 162px;
  }
`

const SectionsLinksContainer = styled.div`
  display: flex;
  margin: 0 auto 0 4%;
  width: 47%;

  a {
    display: block;
    width: fit-content;
    text-decoration: none;
    color: ${colors.tertiary};
    font-size: 14px;
    line-height: 33px;
    margin-right: 30px;
  }

  @media(max-width: 1160px) {
    width: 85vw;
    margin: 30px 0 40px;

    a {
      line-height: 40px;
    }
  }

  @media ${device.phone} {
    display: flex;
    flex-direction: column;
    margin: 30px 0 40px;
  }
`

const FooterLegal = styled.div`
  color: ${colors.tertiaryGrey};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  
  margin: 0;
  padding: 0 10px;

  a {
    color: ${colors.primary};
    transition: .2s;
    :hover {
      color: ${colors.primaryDark};
    }
    :active {
      color: ${colors.primaryLight};
    }
  }
`
export default ({footer, sideMenu}) => {

  const {links, legal} = footer
  
  return (
    <FooterSection>

      <FooterContainer>
        <FooterRow>

          <TopSection>
            <Logo to={sideMenu.home} aria-label="Binaria - Logo">
              <img src={logoImg} alt="logo binaria text" />
            </Logo>

            <SectionsLinksContainer>
              {links.map((section, i) => (
                <Link key={i} to={section.slug}>{section.name} </Link>
              ))}
            </SectionsLinksContainer>

            <FooterSocial />
          </TopSection>

          <FooterLegal>
            {legal[0]} <Link to={legal[1].path}>{legal[1].name}</Link>{legal[2]}<Link to={legal[3].path}>{legal[3].name}</Link>.
          </FooterLegal>

        </FooterRow>
      </FooterContainer>

    </FooterSection>
  )
};
