import React, { useEffect, useState} from "react"
import styled from "styled-components"
import { useCookies } from 'react-cookie';
import {Link} from "gatsby"

import colors from '../styles/colors'
import fonts from '../styles/fonts'
import device from '../styles/device'



const Layout = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 30px;
  width: 100vw;
`

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 0 8.3333333%;
  
  @media ${device.tablet} {
    padding: 0 40px;
  }

  @media ${device.phone} {
    padding: 0 20px;
  }
`


const Popup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.tertiary};
  font-family: ${fonts.poppins};
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  background: white;
  padding: 15px;
  border-radius: 2px;
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.2);
  
  p {
      width: 75%;

      a {
        color: ${colors.primary};
        
        :hover {
          color: ${colors.primaryDark};
        }
        :active {
          color: ${colors.primaryLight};
        }
      }

      @media ${device.desktop} {
        width: 70%;
      }
  }

  @media ${device.tablet} {
    flex-direction: column;

    p {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  height: fit-content;
  width: 177px;
  margin: auto 0;

  button {
    padding: 4px 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    -webkit-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    border: 1px solid ${colors.primary};    

    :first-child {
      background-color: ${colors.primary};
      color: white;
      margin-right: 10px;

      :hover {
        background-color: ${colors.primaryDark};
      }
    }

    :last-child {
      color: ${colors.primary};
      background-color: white;

      :hover {
        background-color: ${colors.lightGrey};
      }
    }
  }
`


const CookiesPopup = ({cookiesText}) => {

  const [cookies, setCookie] = useCookies(['']);
  const [close, setClose] = useState(true);
  const {description, link, acceptButton, rejectButton, path} = cookiesText;


  useEffect(() => {
    if(!cookies.binaria) setClose(false);
  }, [cookies])

  const handleAccept = () => {
    setCookie("binaria", "Binaria", [{ path: '/', maxAge: 31536000 }])
    setClose(true)
  }

  const handleCancel = () => {
    setClose(true)
  }

  return ( close ? null :
    <Layout>
    <Container>
      <Popup>
        <p>{description}<Link to={path}>{link}</Link>.</p>

        <ButtonsContainer>
          <button onClick={handleAccept}>{acceptButton}</button>
          <button onClick={handleCancel}>{rejectButton}</button>
        </ButtonsContainer>

      </Popup>
    </Container>
    </Layout>
  )
}

export default CookiesPopup;