import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import device from '../../styles/device'

const SideNavSocialPureS = styled.ul`
  margin: 0;
  padding: 0;

  @media ${device.tablet}{
    order: 1;
  }

  @media ${device.phone}{
    margin-top: 80px
  }

  li {
    font-family: ${fonts.poppins};
    font-size: 16px;
    letter-spacing: 0px;
    list-style: none;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    letter-spacing: -0.71px;
    position: relative;
  }

  a:after{
    position: absolute;
    left: 0;
    top: 25px;
    content: '';
    width: 100%;
    height: 2px;
    background-image: linear-gradient(135deg, #2214FE 0%, ${colors.secondary} 100%);
    transition: .4s;
    transform: scaleX(0);
  }

  a:hover::after{
    transform: scaleX(1);
  }
`

//Iteramos en el objeto que recibe el componente para formar el menu
const SideNavSocialPure = ({ data }) => (
  <SideNavSocialPureS>
    { data.wordpressWpApiMenusMenusItems.items.map( item => 
      <li key={item.wordpress_id}><a href={ item.url } target='_blank' rel="noopener noreferrer">{ item.title }</a></li>
    )}
  </SideNavSocialPureS>
)

//Componente con otro componente StaticQuery de gatsby con la consulta graphql para recuperar el menu de wp y pasarle los datos un componente puro que los pinta
const SideNavSocial = () => (
  <StaticQuery
    query={graphql`
      query SideNavSocialQuery {
        wordpressWpApiMenusMenusItems(name:{eq:"Footer Social Menu"}) {
          items {
            title
            url
            wordpress_id
          }
        }
      }
    `}
    render={data => <SideNavSocialPure data={data} />}
  />
)

export default SideNavSocial