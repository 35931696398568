import React from 'react'
import styled from 'styled-components'

import colors from '../styles/colors'

import facebook from '../images/ico-facebook.svg'
import twitter from '../images/ico-twitter.svg'
import linkedin from '../images/ico-linkedin.svg'
import instagram from '../images/ico-instagram.svg'

const FooterSocialS = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: flex;
    margin-right: 20px;
  }

  li:last-child {
    margin-right: 0;
  }

  a {
    background: ${colors.lightGrey};
    border-radius: 100%;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: .2s;
  }

  a:hover {
    background: ${colors.tertiary};
    transform: scale(1.1)
  }
`

export default () => (
  <FooterSocialS>
    <li><a href="https://www.linkedin.com/company/binaria-agencia-interactiva/" target='_blank' rel="noopener noreferrer"><img src={ linkedin } alt="linkedin" /></a></li>
    <li><a href="https://www.instagram.com/labinaria/" target='_blank' rel="noopener noreferrer"><img src={ instagram } alt="instagram" /></a></li>
    <li><a href="https://www.facebook.com/binaria.agencia" target='_blank' rel="noopener noreferrer"><img src={ facebook } alt="facebook" /></a></li>
    <li><a href="https://twitter.com/labinaria" target='_blank' rel="noopener noreferrer"><img src={ twitter } alt="twitter" /></a></li>
  </FooterSocialS>
)