import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import device from '../../styles/device'

const SideNavLangS = styled.ul`
  margin: 0 0 70px;
  padding: 0;

  @media ${device.phone}{
    order: 2;
    margin: 0;
  }

  li {
    font-family: ${fonts.poppins};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    list-style: none;
    margin-bottom: 10px;
  }

  a, p {
    text-decoration: none;
    color: ${colors.tertiary};
    letter-spacing: -0.71px;
    position: relative;
    width: fit-content;
  }

  p {
    cursor: default;
    margin: 0;
  }

  p:after {
    position: absolute;
    left: 0;
    top: 25px;
    content: '';
    width: 100%;
    height: 2px;
    background-image: linear-gradient(135deg, #2214FE 0%, ${colors.secondary} 100%);
  }

  a:after {
    position: absolute;
    left: 0;
    top: 25px;
    content: '';
    width: 100%;
    height: 2px;
    background-image: linear-gradient(135deg, #2214FE 0%, ${colors.secondary} 100%);
    transition: .4s;
    transform: scaleX(0);
  }

  a:hover::after{
    transform: scaleX(1);
  }
`



const SideNavLang = ({ data, languages, handleStatus }) => {

  const isCat = typeof window !== "undefined" && window.location.pathname.includes("/ca");
  const isEng = typeof window !== "undefined" && window.location.pathname.includes("/en");
  const isEsp = !isCat && !isEng;
  const active = (isCat && "cat") || (isEsp && "esp") || (isEng && "eng");
  
  return (
    <SideNavLangS>

      <li>
        {active === "esp" ? <p>{languages[0]}</p> : <Link onClick={ () => handleStatus() } to={"/"} >{languages[0]}</Link>}
      </li>

      <li>
        {active === "cat" ? <p>{languages[1]}</p> : <Link onClick={ () => handleStatus() } to={"/ca"} >{languages[1]}</Link> }
      </li>

      <li>
        {active === "eng" ? <p>{languages[2]}</p> : <Link onClick={ () => handleStatus() } to={"/en"} >{languages[2]}</Link> }
      </li>

    </SideNavLangS>
  )
};

export default SideNavLang;