
import React, {Fragment} from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'animate.css/animate.min.css'

import Header from "../components/header"
import Footer from "../components/footer"
import CookiesPopup from '../components/cookiesPopup'
import SEO from "../components/seo"

import device from "../styles/device"

const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none;
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .no-scroll-y {
    overflow-y: hidden;
  }

  .vostokConnectContainer {
    height: 100%;
    width: 100%;
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  /* padding-bottom: 160px; */

  @media ${device.tablet} {
    /* padding-bottom: 220px; */
  }

  @media ${device.phone} {
    /* padding-bottom: 239px; */
  }
`

export default ({negative, children, sideMenu, cookies, footer}) => (
  <Fragment>

    <GlobalStyle/>

      <Helmet>
          <title>Desarrollo y Diseño Web | Binaria, agencia digital</title>
          <meta name="description" content="Binaria es una agencia digital de Barcelona con 20 años de trayectoria. Hacemos diseño UI/UX, sitios web, progressive web apps y otros productos digitales." />
          <html lang="es" />
      </Helmet>

      <SEO/>

      <Layout>
        <Header negative={negative} sideMenu={sideMenu}/>
        <CookiesPopup cookiesText={cookies}/>
        {children}
        <Footer footer={footer} sideMenu={sideMenu}/>
      </Layout>

  </Fragment>
)
