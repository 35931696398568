import React, {useEffect} from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import logoImg from '../images/logo.svg'
import logoImgIcon from '../images/binaria_logotip_2019.png'
import logoImgNegative from '../images/logoNegative.svg'


const LogoWrapp = styled(Link)`
  z-index: 20;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LogoText = styled.img`
  position: absolute;
  left: 0;
  flex-grow: 0;
  max-width: 162px;
  transition: all .4s;

  &.switchLeft {
    left: -200px;
    transition: all .4s;
  }
`

const LogoSrc = styled.div`
  display: flex;
  flex-grow: 0;
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25); 
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s;

  &.logoScroll.switchLeft {
    left: 0;
    opacity: 1;
    visibility: visible;
    transition: all .4s;
  }

  img {
    width: 50%;
    height: 50%;
    margin: 10px auto auto;
  }
`

const Logo = ({negative, positionHeader, sideMenu, handleStatus, status, switchColors}) => {

  let logoText = negative === "light" ? <LogoText className="logoInitial" src={logoImgNegative} alt="logo binaria text" /> : <LogoText className="logoInitial" src={logoImg} alt="logo binaria text" /> ;

  const onClick = () => {
    handleStatus()
    switchColors()
  }


  useEffect(() => {
    const classChanger = () => (!positionHeader ?
      (document.querySelector('.logoInitial').classList.remove('switchLeft'),
      document.querySelector('.logoScroll').classList.remove('switchLeft'))
    :
      (document.querySelector('.logoInitial').classList.add('switchLeft'),
      document.querySelector('.logoScroll').classList.add('switchLeft'))
    );
    classChanger();
  }, [positionHeader]);

  return (
    <LogoWrapp to={sideMenu.home} aria-label="Binaria - Logo" onClick={() => status === false && onClick()}>
      {logoText}
      <LogoSrc className="logoScroll">
        <img src={logoImgIcon} alt="" />
      </LogoSrc>
    </LogoWrapp>
  )

}

export default Logo