import React, {useState, useEffect} from 'react'
import styled from "styled-components"

import device from '../styles/device'

import Logo from './logo'
import SideNav from './sideNav'

const HeaderContent = styled.div`
  height: 100px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media ${device.phone}{
    padding: 0 20px;
  }
`

const Header = ({negative, sideMenu}) => {

  const [position, setPosition] = useState(0)
  const [colorMenu, setColorMenu] = useState(negative)

  const [status, setStatus] = useState(true);
  
  // esta función establece el estado del sidenav true|false y oculta la scrollbar del body si el sidenav está visible
  const switchSidenav = () => {

    if(status === true) {
      setStatus(false)
      document.body.classList.add('no-scroll-y')
    } else {
      setStatus(true)
      document.body.classList.remove('no-scroll-y')
    }

    return status
  }


  const handleSwitchColors = () => (
    negative === "light" ? ( colorMenu === negative ? ( setColorMenu("dark"), setPosition(0) ) : (setColorMenu("light"), setPosition(window.pageYOffset)) ) : ( position !== 0 ? setPosition(0) : setPosition(window.pageYOffset) )
  )

  useEffect(() => {

    const checkDistanceToTop = () => {
      setPosition(window.pageYOffset)
    }

    window.addEventListener("scroll", checkDistanceToTop)
    
    return () => {
      window.removeEventListener("scroll", checkDistanceToTop)
    }
  })


  return (

    <HeaderContent>
      <Logo negative={colorMenu} positionHeader={position} sideMenu={sideMenu} switchColors={handleSwitchColors} handleStatus={ () => switchSidenav() } status={status}/>
      <SideNav negative={colorMenu} sideMenu={sideMenu} switchColors={handleSwitchColors} handleStatus={ () => switchSidenav() } status={status}/>
    </HeaderContent>

  )
}

export default Header