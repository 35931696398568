export default {
    primary: '#4836ff',
    primaryDark: '#372cab',
    primaryLight: '#e4e1ff',
    secondary: '#FC4C5D',
    secondaryDark: '#C3515C',
    secondaryLight: '#FFEBE6',
    tertiary: '#211f35',
    tertiaryDark: '#171717',
    tertiaryLight: '#888888',
    tertiaryGrey: '#b0b0b3',
    white: '#FFFFFF',
    lightGrey: '#f2f2f2',
    introText: "#d0cee1"
};